<template>
  <div class="card">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        {{ $t("productgroup") }}
        <!-- สาขาที่รับสินค้า : {{ branchId }} -->
      </h3>
      <div class="card-toolbar">
        <!-- <button
          type="button"
          class="btn btn-sm btn-light btn-success btn-hover-scale me-2"
          style="font-size: 14px; font-weight: bold"
          @click="gotoImportExcel()"
        >
          นำข้อมูลเข้าจาก Excel
        </button> -->
        <button
          v-if="userAuth.isCanCreate"
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/Flatten.svg" /> </span
          >{{ $t("addpricelist") }}
        </button>
      </div>
    </div>
    <div class="card-title row ms-5 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <input
          v-model="searchInput"
          :placeholder="$t(`search`)"
          type="text"
          class="form-control"
          @keypress.enter="search()"
        />
      </div>
      <div class="col-sm-1 text-end">
        <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="search()"
        >
          {{ $t("search") }}
        </button>
      </div>
    </div>
    <div class="card-body" style="padding-top: 3px">
      <div class="table-responsive">
        <a-table :dataSource="dataItemsShow" :columns="columns">
          <!-- <template
            #customFilterDropdown="{
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              column,
            }"
          >
            <div style="padding: 8px">
              <a-input
                ref="searchInput"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                size="small"
                style="width: 90px; margin-right: 8px"
                @click="handleSearch(selectedKeys, confirm, column.dataIndex)"
              >
                <template #icon><SearchOutlined /></template>
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px"
                @click="handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
          </template>
          <template #customFilterIcon="{ filtered }">
            <search-outlined
              :style="{ color: filtered ? '#108ee9' : undefined }"
            />
          </template> -->
          <template #headerCell="{ column }">
            <template v-if="column.dataIndex === 'no'">
              {{ $t("no") }}
            </template>
            <template v-if="column.dataIndex === 'code'">
              รหัส pricelist
            </template>
            <template v-if="column.dataIndex === 'name'">
              ชื่อ pricelist
            </template>
          </template>

          <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'no'">
              {{ record.no }}
            </template>
            <template v-if="column.dataIndex === 'code'">
              {{ record.code }}
            </template>
            <template v-if="column.dataIndex === 'name'">
              {{ record.name }}
            </template>

            <template v-if="column.dataIndex === 'status'">
              <span v-if="record.status == 'ขายปกติ'" style="color: green">{{
                record.status
              }}</span>
              <span v-else style="color: red">{{ record.status }}</span>
            </template>
            <template v-if="column.dataIndex === 'action'">
              <div class="row">
                <div
                  class="col-sm-6 d-flex justify-content-md-end"
                  v-if="userAuth.isCanModified"
                >
                  <button
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-success p-0"
                    @click="goToEdit(record.id)"
                  >
                    <i class="bi bi-pencil-square"></i>
                  </button>
                </div>
                <div class="col-sm-6" v-if="userAuth.isCanDelete">
                  <button
                    @mouseenter="hoverBBtn"
                    type="button"
                    class="btn btn-link btn-color-muted btn-active-color-danger p-0"
                    @click="goToDelete(record, index)"
                  >
                    <i class="bi bi-trash3-fill"></i>
                  </button>
                </div>
              </div>
            </template>
          </template>
        </a-table>

        <!-- <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="
                fw-bold
                fs-5
                text-gray-800
                border-bottom-2 border-gray-200
                mw-200px
              "
            >
              <th>{{ $t("no") }}</th>
              <th>{{ $t("productgroupcode") }}</th>
              <th>{{ $t("productgroupname") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="12">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>{{ !showing1 ? index + 1 : showing1 + index }}</td>

              <td>{{ item.code }}</td>
              <td>{{ item.name }}</td>
              <td></td>

              <td>
                <div>
                  <button
                    type="button"
                    class="
                      btn btn-link btn-color-muted btn-active-color-success
                      me-sm-2
                      p-0
                    "
                    @click="goToEdit(item.id)"
                  >
                    <i class="bi bi-pencil-square"></i>
                  </button>

                  <button
                    type="button"
                    class="
                      btn btn-link btn-color-muted btn-active-color-danger
                      m-0
                      p-0
                    "
                    @click="goToDelete(item, index)"
                  >
                    <i class="bi bi-trash3-fill"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table> -->
      </div>
    </div>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />

    <!-- <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div> -->
  </div>
</template>

<script>
import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";
import moment from "moment";

import userApi from "@/api/user/";
import { Decode } from "@/services";

export default {
  components: {
    DialogConfirmByPass,
  },
  setup() {
    document.title = "J&N | สร้างใบรับสินค้าออก";
  },
  data: () => ({
    columns: [
      { title: "no", dataIndex: "no" },
      { title: "productgroupcode", dataIndex: "code" },
      { title: "productgroupname", dataIndex: "name" },
      { title: "", dataIndex: "action" },
    ],

    isApprove: false,
    dialogConfirmByPass: false,
    dataItemsShow: [],
    tableItems: [],
    showing1: null,
    warehouses: [],
    searchInput: "",

    userAuth: [],
    userLocal: [],
    masterMenuId: 8,
  }),
  created() {
    this.checkMenu();
    this.getAll();
    // this.getAllWarehouse();
  },
  methods: {
    async checkMenu() {
      this.userLocal = JSON.parse(
        Decode.decode(localStorage.getItem("user_temp"))
      );

      let responseData = [];
      try {
        responseData = await userApi.user.checkAuth(
          this.userLocal.userGroupId,
          this.masterMenuId
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.userAuth = responseData.data[0];
        console.log("userAuth", this.userAuth);
        if (!this.userAuth.isCanView) {
          // Swal.fire({
          //   icon: "error",
          //   title: "ไม่มีสิทธิ์เข้าใช้งาน",
          //   text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
          // });
          this.$router.push("/404");
        }
      } else {
      }
    },
    async getAll(companyId) {
      let responseData = [];
      try {
        responseData = await whApi.pricelist.getAll(
          localStorage.getItem("companyId")
        );
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        console.log("this.dataItemsShow", this.dataItemsShow);
        // this.getProductBywarehouseId();
      }
    },
    // async getAllWarehouse() {
    //   let responseData = [];
    //   try {
    //     responseData = await whApi.warehouse.getAll();
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   if (responseData.response_status === "SUCCESS") {
    //     this.warehouses = responseData.data;
    //     console.log("this.dataItemsShow", this.dataItemsShow);
    //     // this.getProductBywarehouseId();
    //   }
    // },
    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end
    goToNew() {
      this.$router.push("/warehouse/PRICELIST/new");
    },
    goToEdit(id) {
      this.$router.push(
        `/warehouse/PRICELIST/edit?id=${id}`
        //   {
        //   name: "/sale/SA4-1/edit",
        //   params: {
        //     id: id,
        //   },
        // }
      );
    },
    gotoImportExcel() {
      this.$router.push("/warehouse/WH4/importexcel");
    },
    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `กลุ่มสินค้า ${item.name} หรือไม่ ?`,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      let deleteResponse = [];
      deleteResponse = await whApi.pricelist.delete(item.id);
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "ลบสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(index, 1);
          this.tableItems.splice(index, 1);
          this.getAll(1);
        });
      }
    },
    formatDate(date) {
      if (date) {
        const oldDate = moment(date).format("DD/MM/YYYY - HH:mm:ss น.");
        return oldDate;
      } else {
        return "-";
      }
    },
    formatWarehouse(warehouseId) {
      if (warehouseId) {
        const result = this.warehouses.filter(
          (element) => warehouseId == element.id
        );
        console.log("result", result[0].warehouse_full_name);
        return result[0].warehouse_full_name;
      } else {
        return "-";
      }
    },
    handleApprove() {
      this.dialogConfirmByPass = true;
    },
    submitConfirmPass() {
      this.isApprove = true;
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.isApprove = false;
      this.dialogConfirmByPass = false;
    },

    async search() {
      this.loading = true;
      let body = {
        search: this.searchInput,
        // productGroupId: this.productGroupId,
        companyId: localStorage.getItem("companyId"),
      };
      const responseSearch = await whApi.pricelist.search(body);

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.dataItemsShow.forEach((element, index) => {
          element.no = index + 1;
        });
        this.tableItems = responseSearch.data;
        // this.allData = responseSearch.data;
        this.loading = false;
      }
    },
  },
};
</script>
